import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AuthState, OktaAuthService } from './OktaAuthService';

export interface ContinuousAuthState {
    authState?: AuthState;
    getHeaders: (autoLogin?: boolean) => Promise<{ [key: string]: string }>;
    isAuthenticated: boolean;
}

export class OktaAuthReact extends OktaAuthService {

    private ContinuousAuthContext = createContext<ContinuousAuthState | null>(null);

    public useAuthState = () => {
        const [authState, setAuthState] = useState<AuthState>();

        useEffect(() => {
            const authStateHandler = (authState: AuthState) => {
                setAuthState(authState.isAuthenticated ? authState : undefined);
            }

            this.subscribe(authStateHandler)

            return () => {
                this.unsubscribe(authStateHandler);
            };
        }, []);

        return authState;
    };

    public useContinuousAuth = () => {
        const continuousAuth = useContext(this.ContinuousAuthContext);

        if (continuousAuth === null) {
            throw new Error('Missing ContinuousAuthProvider');
        }

        return continuousAuth;
    };

    public ContinuousAuthProvider = (props: PropsWithChildren<{}>) => {
        const authState = this.useAuthState();

        // refs to be used by the getHeaders wrapper
        const authStateRef = useRef<AuthState | undefined>(undefined);
        const truthyAuthTriggersRef = useRef<Set<() => void>>(new Set());

        // get headers wrapper waiting for a valid auth state
        const getHeadersWrapper = useCallback(async (autoLogin: boolean = true) => {
            try {
                return await this.getHeaders(autoLogin);
            } catch (err) {
                // ensure we have truthy auth state, then call getHeaders with no auto login
                return new Promise<{ [key: string]: string }>((resolve, reject) => {
                    const truthyTrigger = () => {
                        this.getHeaders(false).then(resolve, reject);

                        truthyAuthTriggersRef.current.delete(truthyTrigger);
                    };

                    truthyAuthTriggersRef.current.add(truthyTrigger);

                    // trigger sync if we have truthy auth state already
                    if (authStateRef.current) {
                        truthyTrigger();
                    }
                })
            }
        }, []);

        const [continuousAuthState, setContinuousAuthState] = useState({
            authState,
            getHeaders: getHeadersWrapper,
            isAuthenticated: !!authState,
        });

        useEffect(() => {
            authStateRef.current = authState;

            setContinuousAuthState(oldAuthState => ({
                ...oldAuthState,
                ...(authState && { authState }), // cache last truthy auth state
                isAuthenticated: !!authState,
            }));

            if (authState) {
                // trigger all waiting get headers
                truthyAuthTriggersRef.current.forEach(trigger => trigger());
            }
        }, [authState]);

        return (
            <this.ContinuousAuthContext.Provider value={continuousAuthState}>
                {props.children}
            </this.ContinuousAuthContext.Provider>
        );
    };

}

