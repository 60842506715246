import React from 'react';
import RingUserLookupPage from './RingUserLookupPage';
import { OktaAuthReact } from '@amzn/ring-cs-okta-auth-client';
import settings from '../settings.json';
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../components/layouts/font-face.css';

// prep this site to use Okta
const { okta: { iss, cid } } = (settings as any)[window.location.host] || settings.default;
export const {
	ContinuousAuthProvider,
	useAuthState,
	useContinuousAuth,
	login,
	logout,
	getHeaders,
} = new OktaAuthReact(iss, cid);

function App() {
	return (
		<RingUserLookupPage />
	);
}

export default App;
