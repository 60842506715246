import React, { useState } from 'react';
import PageContent from '../components/layouts/PageContent';
import styled from 'styled-components';
import { getHeaders } from './App';

import { Input, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import axios from "axios";
import settings from "../settings.json";

//API Prefix & OktaAuth Defined
const {
    api_prefix: apiPrefix,
} = (settings as any)[window.location.host] || settings.default;


const LookupSearchWrapper = styled.div`
    height: 50px;
    width: 350px;
    padding: 30px;
    font-family: 'equiplight';
    
    & Input {
        background-color: #fff;
        font-size: 16px;
        text-indent: 15px;
    }
`

const LookupSearchIcon = styled.a`
`

export const LookupSearch = () => {
    const [amazonId, setAmazonId] = useState('')
    const [response, setResponse] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    /**
     * Sends a request to the API to find the ring user associated with the inputted amazon id
     */
    const sendRequest = async () => {
        if (!amazonId) {
            return;
        } else {
            setLoading(true);
            setError('');
            try {
                const headers = await getHeaders();
                const data = await axios.get(apiPrefix + `/api/user-lookup?amazonCustomerId=${amazonId.toUpperCase()}`, { headers });

                console.log("Results: ", data);
                const result = data.data;

                setResponse(result.ringUserId || result.message);
            } catch ({ message }) {
                setError("There was a connection error");
                console.error('ERROR: ', message);
            } finally {
                setLoading(false)
            }

        }
    }

    /**
     * Changes the class's field so that the result is cleared and the input value is
     * saved correctly.
     */
    const handleInputChanged = (event: { target: { value: string; }; }) => {
        setAmazonId(event.target.value.replace(/[^a-zA-Z0-9]/g, ''));
        setResponse('');
    }

    return (
        <LookupSearchWrapper>
            <Input
                placeholder="Enter Amazon ID here"
                onChange={handleInputChanged}
                value={amazonId}
                onPressEnter={sendRequest}
                suffix={
                    <LookupSearchIcon onClick={sendRequest}>
                        <SearchOutlined
                            style={{ color: '#424242', verticalAlign: 'text-bottom', fontSize: '20px' }}/>
                    </LookupSearchIcon>
                }
                style={{
                    width: '400px',
                    borderColor: '#f6f8fa',
                    marginBottom: '30px'
                }}
            />
            {loading ? <Spin/> : ([
                response,
                error
            ])}
        </LookupSearchWrapper>
    )
}

/**
 * Class that holds the elements for the Ring User Lookup tool available to Ring Employees, has all the
 * elements of the page. Using Styled Components to style
 */
function RingUserLookupPage() {
    return (
        <PageContent
            headerColor='#10628a'
            header="CS Tools"
            subheader="Ring User Lookup"
            authenticated={true}
            expanded={true}
            footer={true}
        >
            <LookupSearch/>
        </PageContent>
    )
}

export default RingUserLookupPage;
