{
    "default": {
        "okta": {
            "cid": "0oat43wqfwjIhbM4H1t7",
            "iss": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
        },
        "api_prefix": "https://user-lookup.cs-tools.dev.ring.com"
    },
    "user-lookup.cs-tools.dev.ring.com": {
        "okta": {
            "cid": "0oat43wqfwjIhbM4H1t7",
            "iss": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
        },
        "api_prefix": "https://user-lookup.cs-tools.dev.ring.com"
    },
    "user-lookup.cs-tools.qa.ring.com": {
        "okta": {
            "cid": "0oat43wqfwjIhbM4H1t7",
            "iss": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
        },
        "api_prefix": "https://user-lookup.cs-tools.qa.ring.com"
    },
    "user-lookup.cs-tools.ring.com": {
        "okta": {
            "cid": "0oat43eozleZ92Jt81t7",
            "iss": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
        },
        "api_prefix": "https://user-lookup.cs-tools.ring.com"
    }
}
