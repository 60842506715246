import {
    AuthState,
    AccessToken,
    AuthStateEventHandler,
    OktaAuth,
    TokenParams,
} from '@okta/okta-auth-js';

const loginOptions: TokenParams = {
    scopes: ['openid', 'email', 'profile'],
    responseType: ['id_token', 'token'],
};

export {
    AuthState,
} from '@okta/okta-auth-js';

export class OktaAuthService {

    private readonly issuer: string;
    private readonly clientId: string;

    protected readonly authStateHandlers = new Set<(auth?: AuthState) => void>();

    private authClient: OktaAuth | undefined;

    constructor(issuer: string, clientId: string) {
        this.issuer = issuer;
        this.clientId = clientId;
    }

    public getAuthClient = async () => {
        if (!this.authClient) {
            this.authClient = new OktaAuth({
                clientId: this.clientId,
                issuer: this.issuer,
                redirectUri: window?.location?.origin + '/implicit/callback',
                tokenManager: {
                    storageKey: 'popup-okta-token-storage-' + this.clientId,
                }
            });
            await this.authClient.start();
        }
        return this.authClient;
    };

    public subscribe = async (handler: AuthStateEventHandler) => {
        const authClient = await this.getAuthClient();

        authClient.authStateManager.subscribe(handler);

        // give initial state if it exists
        const authState = authClient.authStateManager.getAuthState();
        if (authState) handler(authState);
    };

    public unsubscribe = async (handler?: AuthStateEventHandler) => {
        const authClient = await this.getAuthClient();

        authClient.authStateManager.unsubscribe(handler);
    };

    // expired token check
    private isTokenExpired = (token: AccessToken): boolean => {
        const currentTime = Math.floor(Date.now() / 1000); // in seconds
        const expirationTimeBuffered = token.expiresAt - 15; // subtract 15 seconds to provide a buffer
        return currentTime >= expirationTimeBuffered;
    };

    public getAccessToken = async (autoLogin: boolean): Promise<AccessToken | undefined> => {
        const authClient = await this.getAuthClient();

        console.debug('getting access token');

        const token = (await authClient.tokenManager.getTokens()).accessToken;

        console.debug('checking if expired');

        // check if no token or token has expired and did not auto-renew
        if (!token || this.isTokenExpired(token)) {
            console.debug('expired');

            // auto login if flagged to do so
            if (autoLogin) {
                console.debug('auto logging in');

                const tokens = await this.login();

                console.debug('returning login result');

                return tokens.accessToken;
            } else {
                console.error('auto logging out');
                await this.logout();

                throw new Error('Access token does not exist or has expired');
            }
        } else {
            console.debug('not expired');
            return token;
        }
    };

    public login = async () => {
        const authClient = await this.getAuthClient();
        try {
            const result = await authClient.token.getWithPopup(loginOptions);
            const tokens = result.tokens;
            authClient.tokenManager.setTokens(tokens);
            return tokens;
        } catch (err) {
            console.error('Login failed: ', err);
            throw err;
        }
    };

    public logout = async () => {
        const authClient = await this.getAuthClient();
        try {
            authClient.revokeAccessToken();
            authClient.revokeRefreshToken();
        } catch (err) {
            console.error(err);
        }
        authClient.tokenManager.clear();
    };

    public getHeaders = async (autoLogin: boolean = true): Promise<{ [key: string]: string }> => {
        const accessToken = await this.getAccessToken(autoLogin);

        return {
            Authorization: `Bearer ${accessToken?.accessToken}`,
        };
    };
}

